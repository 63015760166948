<template>
  <SectionBlock title="Account Details">
    <NuxtLink
      v-if="customer"
      :to="path || '/account/edit'"
      class="flex justify-between"
      aria-label="edit account"
    >
      <div class="flex flex-col">
        <div class="font-bold">
          {{ customer.firstName }} {{ customer.lastName }}
        </div>
        <div class="text-gray-600">
          {{ customer.email }}
        </div>
        <div
          v-if="customer.phone"
          class="text-gray-600"
          data-sentry-mask
        >
          {{ formatPhone(customer.phone) }}
        </div>
      </div>
      <div class="flex items-center pr-2">
        <SvgIcon
          name="chevron-right"
          class="w-3 h-3 text-gray-600"
        />
      </div>
    </NuxtLink>
  </SectionBlock>
</template>

<script setup lang="ts">
import { useCustomerStore } from '@/stores/shopify'
export interface Props {
  path?: string
}
defineProps<Props>()
const store = useCustomerStore()
const customer = store.useCustomer()
</script>
